import { HTTP_API, makeRequest } from "../HttpClient";
import queryString from "query-string";

const API_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : "";

export default class UserService {
  static async getUserList({
    cognitoUserId = "",
    toshiosAuthId = "",
    email = "",
    sort = "",
    page = "",
    limit = "",
    keywordList = [],
  }) {
    const params = [];
    if (keywordList) {
      keywordList.map((keyword) => params.push(`keyword=${keyword}`));
    }
    if (cognitoUserId) {
      params.push(`cognitoUserId=${cognitoUserId}`);
    }
    if (toshiosAuthId) {
      params.push(`toshiosAuthId=${toshiosAuthId}`);
    }
    if (email) {
      params.push(`email=${email}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);

    // var url = 'https://d1t6xkzunp44ld.cloudfront.net/api/v1.0/shops/123135';
    var url = `${API_BASE_URL}/users?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async createUser(email, displayName, cognitoUserId) {
    var params = {
      email: email,
      displayName: displayName,
      cognitoUserId: cognitoUserId,
    };
    var url = `${API_BASE_URL}/users`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async editUser(userId, params) {
    var url = `${API_BASE_URL}/users/${userId}`;
    return makeRequest(HTTP_API.PUT, url, params);
  }

  static async getFriendList({
    keyWord = "",
    sort = "",
    page = "",
    limit = "",
    loginUserId = "",
    filterName = "",
  }) {
    const params = [];

    if (keyWord) {
      params.push(`keyword=${keyWord}`);
    } else {
      if (filterName) {
        if (filterName !== "allUser") {
          params.push(`${filterName}=${loginUserId}`);
          if (loginUserId) {
            params.push(`loginUserId=${loginUserId}`);
          }
        }
      }
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);

    var url = `${API_BASE_URL}/users?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getFriendInfo(userId) {
    var url = `${API_BASE_URL}/users/${userId}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async getListFollow({
    loginUserId,
    followerUserId,
    followUserId,
    sort,
    keyword,
    page,
    limit,
  }) {
    const params = [];

    if (loginUserId) {
      params.push(`loginUserId=${loginUserId}`);
    }
    if (followerUserId) {
      params.push(`followerUserId=${followerUserId}`);
    }
    if (followUserId) {
      params.push(`followUserId=${followUserId}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    if (keyword) {
      params.push(`keyword=${encodeURIComponent(keyword)}`);
    }
    params.push(`page=${page}`);
    params.push(`limit=${limit}`);

    var url = `${API_BASE_URL}/users?${params.join("&")}`;
    return makeRequest(HTTP_API.GET, url);
  }

  static async checkUserAvailable(accessCode) {
    var params = {
      qrCode: accessCode,
    };
    var url = `${API_BASE_URL}/auth/qr-code`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async deleteAccount(userId) {
    var url = `${API_BASE_URL}/v3/users/${userId}`;
    return makeRequest(HTTP_API.DELETE, url);
  }
  
  static async createToshiosUser(toshiosIdToken, email, domain) {
    const params = {
      email: email,
      toshiosIdToken,
      domain
    };
    const url = `${API_BASE_URL}/users/toshios`;
    return makeRequest(HTTP_API.POST, url, params);
  }

  static async linkToshiosUser(toshiosIdToken, domain) {
    const params = {
      toshiosIdToken,
      domain
    };
    const url = `${API_BASE_URL}/users/toshios/link`;
    return makeRequest(HTTP_API.POST, url, params);
  }
}

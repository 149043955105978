import React, { useState, useEffect } from "react";  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons' 
import {log} from '../../../../utils'; 
import PaymentService from "../../../../api/services/PaymentService";
import * as constants from '../../../../constants';
import {savePaymentMethod} from "../../../../actions/action_payment";
import PaymentCardBrands from "../../../common/PaymentCardBrands";
import logoAtone from "../../../../assets/img/icons/logo_atone.png";
import AtoneDescriptionPopup from "../../common/AtoneDescriptionPopup";

export function Payment(props) {

    const [atoneHide, setAtoneHide] = useState(false);

    useEffect(() => {
      if (!window.Atone) {
        setAtoneHide(true);
      } else {
        setAtoneHide(false);
      }
    }, [window.Atone]);

    useEffect(() => {
        setDefaultPaymentType()
    }, [props.gochiData]);

	const [showAtoneDescription, setShowAtoneDescription] = useState(false);
    
    const selectPayment = props.selectPayment;

	const paymentTypes = props.shopInfo && props.shopInfo.paymentTyptes ? props.shopInfo.paymentTyptes : [];

    function setDefaultPaymentType() {
        if (props.gochiData) {
            if (!selectPayment) {
                props.setSelectPayment(constants.PAYMENT_TYPE_VERITRANS);
            }
        }
    }

	let isCreditCard = parseInt(selectPayment) === constants.PAYMENT_TYPE_STRIPE ||
			parseInt(selectPayment) === constants.PAYMENT_TYPE_VERITRANS;

	async function fetchPaymentDefault(userId) { 
		try { 
			if (userId) {
				if (props.paymentMethod.length <= 0) {
					var paymentDefault = await getPaymentDefault(userId);
					if (paymentDefault.list.length > 0){
						props.setPaymentMethod(paymentDefault.list[0]); 
						savePaymentMethod(paymentDefault.list[0]);
					}
				}else{
					savePaymentMethod(props.paymentMethod);
				}
			} 
		} catch (e) {
			log(e); 
		}		
	}

	function openAddCard(){
		if (props.userInfo) {
    		if (props.userInfo.userId) {
	            props.fetchPaymentList(props.userInfo.userId);
	        }
    	}
		props.openAddCard(true);
	}

	async function getPaymentDefault(userId){ 
			try {
			  	const { data } = await PaymentService.getDefaultCard(userId);
			  	return data;
			} catch (error) { 
			  	log('getDefaultCard error'); 
			  	props.showError(true, "ログインがキャンセルされました。", "");
			}
	};

	useEffect(() => {
		var userInfo = props.userInfo;
    	if (userInfo) {
    		if (userInfo.userId) {
	            fetchPaymentDefault(userInfo.userId);
	        }
    	}
	}, [props.paymentList])

	return (
    <div className="payment-box">
      <div className="dish-infor pm-info payment-gochi-charge">
        <div className="required-dot payment-header">
          <div className="dot-red"></div>
          <div className="dot-text-label fs-18 payment-title">お支払い方法</div>
        </div>

        <div className="box-option">
          <div className="d-flex">
            <div className="">
              <label className="radio-custom fs-14 payment-radio">
                <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_PAYPAY}
                  onChange={props.handleActiveButton}
                  checked={parseInt(selectPayment) === constants.PAYMENT_TYPE_PAYPAY}
                />
                <span className="checkmark"></span>
                <span className="payment-method">PayPayで決済</span>
              </label>
            </div>
          </div>
          <div className="d-flex">
            <div className="">
              <label className="radio-custom fs-14 payment-radio">
                <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
                  onChange={props.handleActiveButton}
                  checked={parseInt(selectPayment) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY}
                />
                <span className="checkmark"></span>
                <span className="payment-method">Alipayで決済</span>
              </label>
              {parseInt(selectPayment) === constants.PAYMENT_TYPE_ONEPAY_ALIPAY &&
                <div className="payment-warning-message">※Alipayアプリをインストールしないと決済できません。</div>}
            </div>
          </div>
          <div className="d-flex">
            <div className="">
              <label className="radio-custom fs-14 payment-radio credit-method">
                <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_CREDIT_CARD}
                  onChange={props.handleActiveButton}
                  checked={parseInt(selectPayment) === constants.PAYMENT_TYPE_CREDIT_CARD}
                />
                <span className="checkmark"></span>
                <span className="payment-method">クレジットカードの決済</span>
              </label>
            </div>
          </div>
          {((paymentTypes.filter(item => item.status === 1).length <= 1 && !props.gochiData?.groupId) || (isCreditCard) || (props.gochiData?.groupId && isCreditCard)) && !props.havePaymentMethod && (
            <div className="credit-warning-box">お支払い方法を選択してください</div>
          )}
          {((paymentTypes.filter(item => item.status === 1).length <= 1 && !props.gochiData?.groupId) || (isCreditCard) || (props.gochiData?.groupId && isCreditCard)) &&
            <div className="payment-info">
              <div className="d-flex align-items-end">
                { props.paymentMethod ? 
                  (props.paymentMethod.length === 0) ?
                    <div className="fs-14">
                      お支払い方法を選択してください。
                    </div>
                  :  
                    <div className="credit-box">
                      <div>{props.paymentMethod.brand}****-****-****-{props.paymentMethod.last4}</div>
                      <div className="font-weight-bold">{props.paymentMethod.holder}</div>
                      <div className="payment-date">
                        <div>
                          <span>有効期限: </span>
                          <span>{props.paymentMethod.expireMonth.toString().padStart(2, '0')}/{props.paymentMethod.expireYear}</span>
                        </div> 
                      </div>
                    </div>
                :
                  <div className="fs-14">
                    お支払い方法を選択してください。
                  </div> 
                }

                <div className="ml-auto">
                  <button className="btn btn-white-26 ml-2" onClick={() => {openAddCard()}}>
                    <FontAwesomeIcon icon={faPen} className="mr-2" />
                    {props.paymentMethod ? 
                        (props.paymentMethod.length === 0) ? 
                        "変更する" 
                      : 
                        "変更する"
                    :
                      "変更する" 
                    }</button>
                </div>
              </div> 
              <div className="mt-2 mb-2">
                <PaymentCardBrands />
              </div>
            </div>
          }
          <div className="d-flex">
            <div className="atone-box">
              {!atoneHide &&
              <label className="radio-custom fs-14 payment-radio credit-method">
                <input type="radio" name="paymentType" value={constants.PAYMENT_TYPE_ATONE}
                  onChange={props.handleActiveButton}
                  checked={parseInt(selectPayment) === constants.PAYMENT_TYPE_ATONE}
                />
                <span className="checkmark"></span>
                <span className="payment-method">atone 翌月払い（コンビニ/口座振替）</span>
                <div className="text-red mb-2">※atoneアカウントをお持ちではない方は、会員登録が必要です</div>
              </label>
              }
              <div className="atone-description">
                {!atoneHide ? (
                <div>
                  <a
                    href="https://atone.be/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={logoAtone} alt="Atone" />
                  </a>
                </div>
                ) : (
                  <div className="fs-12">
                    ※広告ブロック等の理由により、atone決済が表示されません。<br />
                    atone決済をご利用ご希望の方は、ご利用のブラウザを変更してお試しください。
                  </div>
                )}
                <span className="font-weight-bold text-decoration-underline" onClick={() => setShowAtoneDescription(true)}>atone説明文</span>
              </div>
            </div>
          </div>
        </div>
        <AtoneDescriptionPopup show={showAtoneDescription} handleClose={() => setShowAtoneDescription(false)} isGochiCharge={true}/>
      </div>
    </div>
	);
}

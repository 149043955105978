import React, { useCallback } from "react";

function ToshiosEmailInput({ email, setEmail, emailError, onSubmit, confirmFlag, onConfirm, setConfirmFlag, isErrorEmail }) {
  const onEmailChange = useCallback((e) => {
    setEmail(e.target.value);
  }, [setEmail]);

  return (
    <div>
      <header className="global-login-header-new forgot-password-header">
        <div className="logo-nav">
          <h1 className="logo">
            <a href="/">
              <div className="new-logo-login">
                <source
                  media="(max-width: 180px)"
                  srcSet="/assets/img/common/logo_gochi_header_mypage.svg"
                />
                <img
                  src="/assets/img/common/logo_gochi_header_mypage.svg "
                  alt="気持ちを「ごちそう」にして贈れるアプリ ごちめし"
                  loading="lazy"
                />
              </div>
            </a>
          </h1>
        </div>
      </header>

      <main>
        <div className="container toshios-email">
          <div className="signup-input">
            <div className="signup-input-group">
              <div className="signup-input-header toshios-header">
                <div className="heading-md">
                  <div className="title toshios-title">境町プラス認証</div>
                </div>
              </div>
              <div className="signup-input-body toshios-body">
                {confirmFlag ? (
                  <div className="confirm-contain">
                    <div className="confirm-title">登録メールアドレス</div>
                    <div className="confirm-email">{email}</div>
                  </div>
                ) : (
                  <>
                    <div className="toshios-description">境町プラス認証で登録を希望するメールアドレスをご入力ください。</div>
                    <div className="fieldset">
                      <div className="fieldset-header">
                        <div className="title">メールアドレス</div>
                        <em className="fieldset-mark">*</em>
                      </div>
                      <div className="fieldset-body">
                        <div className="field-group field-group-column">
                          <div className="field field-fluid">
                            <input className={"form-control " + (emailError ? 'input-error' : '')}
                              type="text"
                              onChange={onEmailChange}
                              value={email}
                              name="email"
                            />
                          </div>
                          {emailError &&
                            <div className="text-danger fs-14 mt-2 fs-12">{emailError}</div>
                          }
                          {isErrorEmail && (
                            <div className="warning">
                              <div>
                                <i className="icon-tosho-error-email"/>
                              </div>
                              <div>
                                このメールアドレスは、既にGOCHIアカウントで登録済みです。
                                <br />
                                <br />
                                登録済みGOCHIアカウントでGOCHIマイページにログイン後、境町プラスID連携をお願いいたします。
                                <br />
                                ログイン：
                                <br />
                                <a href="/login" className="toshios-error-link">https://gochi.online/login</a>
                                <br />
                                <br />
                                もしくは、別のメールアドレスでお試しください。
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  {confirmFlag ? (
                    <div>
                      <button className="btn btn-rect signup-input-btn button-reset-pass btn-toshios" onClick={() => onSubmit()}>登録する</button>
                      <button
                        onClick={() => setConfirmFlag(false)}
                        className={'btn btn-outline-rect btn-text'}
                      >
                        前の画面に戻る
                      </button>
                    </div>
                  ) : (
                    <button className="btn btn-rect signup-input-btn button-reset-pass btn-toshios" onClick={() => onConfirm()}>確認画面へ</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ToshiosEmailInput;

import React, { useEffect, useState } from "react";
import LoadingOverlay from "../../GiftSite/common/LoadingOverlay";
import { useHistory, useRouteMatch } from "react-router-dom";
import UserService from "../../../api/services/UserService";
import AuthService from "../../../api/services/AuthService";
import { HTTP_API } from "../../../api/HttpClient";
import { saveUserInfo } from "../../../actions/action_users";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import ToshiosEmailInput from "./ToshiosEmailInput";

function ToshiosOptIn(props) {
  const history = useHistory();
  const match = useRouteMatch();
  let redirectUrl = localStorage.getItem("toshiosAuthRedirectUrl") || "/login";
  if (props.userInfo.isLogined && !props.userInfo.isGuest) {
    redirectUrl = "/mypage";
  }
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmFlag, setConfirmFlag] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);

  const loginByToshios = async (toshiosIdToken, toshiosAuthId, email, domain) => {
    const { data } = await UserService.getUserList({
      toshiosAuthId,
      sort: "createdAtDesc",
      page: 1,
      limit: 1
    });
    if (data.count <= 0) {
      // Create new user
      const response = await UserService.createToshiosUser(toshiosIdToken, email, domain);
      console.log("created new user:", response);
      if (response.status === HTTP_API.SUCCESS) {
        data.list.push(response.data);
        data.count = 1;
      } else {
        if (response.data?.code === 'exception.errors.toshios.email.required') {
          setShowEmailInput(true);
          setLoading(false);
        } else if (response.data?.code === 'validation.errors.Duplicate') {
          if (showEmailInput) {
            // If user input existed email
            setConfirmFlag(false);
            setIsErrorEmail(true);
            setLoading(false);
          } else {
            // If ToshiOS email already existed
            const element = (<div className="toshi-duplicate-email-error-popup">
              境町プラスのアカウントでご登録されたメールアドレスは、既にGOCHIアカウントで登録されています。
              <br />
              <br />
              登録済みGOCHIアカウントでGOCHIマイページにログイン後、境町プラスID連携をお願いいたします。
              <br />
              ログイン：
              <br />
              <a href='/login'>https://gochi.online/login</a>
            </div>);
            setErrorMessage(element);
            setShowErrorPopup(true);
            setLoading(false);
          }
        } else {
          setLoading(false);
          throw 'Unexpected error when create user';
        }
        return;
      }
    }
    const resAuth = await AuthService.authenticate({
      grantType: 'toshios_token',
      token: toshiosIdToken
    });
    if (resAuth.status !== HTTP_API.SUCCESS) {
      throw "Auth ToshiOS ID token fail";
    }

    props.saveUserInfo(true, false, data.list[0]);

    const userInfo = {
      isLogined: true,
      isGuest: false,
      userId: data.list[0].userId,
      accountId: data.list[0].accountId,
      displayName: data.list[0].displayName,
      email: data.list[0].email,
      userImage: data.list[0].userImage && data.list[0].userImage.length > 0 && data.list[0].userImage[0].image
    };
    localStorage.setItem("userInfo", JSON.stringify(userInfo));

    // redirect after login success
    history.replace(redirectUrl, { auth: "success"});
  };

  const linkToshios = async (toshiosIdToken, domain) => {
    const response = await UserService.linkToshiosUser(toshiosIdToken, domain);
    console.log("link user:", response);
    if (response.status === HTTP_API.SUCCESS) {
      // Show success message
      // setErrorMessage("ToshiOS link success");
      history.replace(redirectUrl);
      setShowErrorPopup(true);
    } else {
      setErrorMessage("エラーが発生しました。もう一度試してください。");
      setShowErrorPopup(true);
    }
  };

  // Chuyển đổi từ Base64 URL-safe sang Base64 tiêu chuẩn
  const convertToStandartBase64 = (str) => {
    // Thay thế ký tự URL-safe bằng ký tự Base64 tiêu chuẩn
    let result = str.replace(/-/g, '+').replace(/_/g, '/');
    // Thêm padding nếu cần
    while (result.length % 4 !== 0) {
      result += '=';
    }
    return result;
  };

  const getDomain = () => {
    const url = window.location.href;
    const arr = url.split("/");
    const domain = arr[0] + "//" + arr[2];
    return domain.replace(/http:\/\/|https:\/\//g, '');
  }

  const optIn = async () => {
    setLoading(true);
    const toshiosIdToken = localStorage.getItem("toshiosIdToken");
    const domain = getDomain();
    if (!toshiosIdToken) {
      console.log("Can not get toshiosIdToken from localStorage");
      setErrorMessage("エラーが発生しました。もう一度試してください。");
      setShowErrorPopup(true);
      return;
    }
    let toshiosAuthId = null;
    try {
      toshiosAuthId = JSON.parse(atob(convertToStandartBase64(toshiosIdToken.split(".")[1]))).sub;
    } catch (e) {
      console.log(e);
    }
    if (!toshiosAuthId) {
      console.log("Can not get toshiosAuthId");
      setErrorMessage("エラーが発生しました。もう一度試してください。");
      setShowErrorPopup(true);
      return;
    }

    try {
      if (props.userInfo.isLogined && !props.userInfo.isGuest) {
        await linkToshios(toshiosIdToken, domain);
        setLoading(false);
      } else {
        await loginByToshios(toshiosIdToken, toshiosAuthId, email, domain);
      }
    } catch (e) {
      console.log(e);
      setErrorMessage("エラーが発生しました。もう一度試してください。");
      setShowErrorPopup(true);
      return;
    }
  };

  const onHideError = () => {
    setShowErrorPopup(false);
    history.replace(redirectUrl);
  };

  useEffect(() => {
    document.body.className = "auth signup";
    if (match.params.error) {
      setErrorMessage("エラーが発生しました。もう一度試してください。");
      setShowErrorPopup(true);
      return;
    }
    optIn();
  }, []);

  const confirmEmail = () => {
    const valid = validEmail();
    if (valid === 0) {
      setConfirmFlag(true);
    } else {
      setIsErrorEmail(false);
    }
  }

  function validEmail() {
    let value = String(email).toLowerCase().trim();
    if (value === "") {
      setEmailError("必須項目です。");
      return 1;
    }
    if (
      !/^(("[\w+-\s]+")|([\w+-]+(?:\.[\w+-]+)*)|("[\w+-\s]+")([\w+-]+(?:\.[\w+-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i.test(
        value
      )
    ) {
      setEmailError("メールアドレスの形式が正しくありません。");
      return 1;
    }
    setEmailError("");
    setEmail(value);
    return 0;
  }

  return (
    <>
      {showEmailInput &&
        <ToshiosEmailInput
          email={email}
          setEmail={setEmail}
          emailError={emailError}
          onSubmit={optIn}
          confirmFlag={confirmFlag}
          setConfirmFlag={setConfirmFlag}
          onConfirm={confirmEmail}
          isErrorEmail={isErrorEmail}
        />
      }
      <Modal show={showErrorPopup} onHide={onHideError} className="modal-error">
        <div className="modal-body">
          <Button variant="secondary" className="close" onClick={onHideError}>&times;</Button>
          <div className="input-infor">
            <div>
              <h5 className="space-pre-line">{errorMessage}</h5>
            </div>
          </div>
        </div>
      </Modal>
      <LoadingOverlay loading={loading && !showErrorPopup} />
    </>
  );
}

const mapStateToProps = state => ({
  userInfo: state.userInfo
});

const mapDispatchToProps = dispatch => ({
  saveUserInfo: (isLogined, isGuest, userInfo) => dispatch(saveUserInfo(isLogined, isGuest, userInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToshiosOptIn);
